<template>
  <template v-if="virtualPosProvider === 'iyzico'">
    <NuxtImg src="/images/payment/iyzico-band-colored.svg" height="10px" class="block h-5" loading="lazy" />
  </template>
  <div class="flex items-center space-x-3" v-else>
    <NuxtImg src="/images/payment/payment-band.png" height="20px" class="block h-5 object-contain" loading="lazy" />
<!--    <IconsMasterCard class="w-8 h-[40px]" />-->
<!--    <IconsVisa class="w-auto h-[40px]" />-->
<!--    <IconsAmex class="w-auto h-[40px]" />-->
<!--    <IconsTroy class="w-auto h-[20px] pl-3" />-->
  </div>
</template>

<script setup>

const storeConfig = useStoreConfig()
const {baseConfig} = storeToRefs(storeConfig)

const virtualPosProvider = baseConfig.value?.virtual_pos_provider;

</script>